import React from "react"

import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import ButtonBase from "@material-ui/core/ButtonBase"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

const ArrowSlickRight = ({ onClick }) => {
  return (
    <ButtonBase className="arrow-btn prev" onClick={onClick}>
      <ArrowBackIosIcon onClick={onClick} color="white" />
    </ButtonBase>
  )
}
export default ArrowSlickRight
