import React from "react"
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"

import * as S from "./text-img-row-slider.styles"
import { Container, Grid } from "@material-ui/core"
import Slider from "react-slick"
import ArrowSlickRight from "../slick-arrows/arrow-slick-prev-arrow.component"
import ArrowSlickLeft from "../slick-arrows/arrow-slick-next-arrow.component"
import CustomImage from "../custom-image/custom-image.component"
import parse from "html-react-parser"

const TextImgRowSlider = ({
  content,
  flipCols,
  imageUrl,
  grayBg,
  children,
  gatsbyImg,
  images,
}) => {
  const settingsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowSlickLeft />,
    prevArrow: <ArrowSlickRight />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  }

  return (
    <S.Wrapper graybg={grayBg}>
      <Container>
        <S.GridContainer flipCols={flipCols} container spacing={6}>
          <Grid item xs={12} sm={6}>
            <Slider {...settingsSlider}>
              {images.map(image => (
                <div>
                  {image.images.localFile ? (
                    <CustomImage src={image.images} />
                  ) : (
                    <CustomImage src={image.images} />
                  )}
                </div>
              ))}
            </Slider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <S.Content>
              {content && parse(content)}
              {children}
            </S.Content>
          </Grid>
        </S.GridContainer>
      </Container>
    </S.Wrapper>
  )
}

export default TextImgRowSlider
