import React, { useState } from "react"
import Layout from "../../components/layout"

import * as S from "../../styles/templates/page-boat.styles"
import CustomSlider from "../../components/custom-slider/custom-slider.component"
import SectionTitle from "../../components/section-title/section-title.component"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import CustomButton from "../../components/custom-button/custom-button.component"
import TopPageBanner from "../../components/top-page-banner/top-page-banner.component"
import TextImgRowSlider from "../../components/text-img-row-slider/text-img-row-slider.component"
import "react-image-lightbox/style.css"
import CustomLightbox from "../../components/custom-lightbox/custom-lightbox.component"
import { graphql } from "gatsby"
import CustomImage from "../../components/custom-image/custom-image.component"
import parse from "html-react-parser"
import { Swiper, SwiperSlide } from "swiper/react"

export const query = graphql`
  query PageBoatQuery($id: String!) {
    wpBoat(id: { eq: $id }) {
      title
      content
      seo {
        cornerstone
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
      }
      uri
      slug
      boatPostTypeFields {
        generalInfo {
          heroBackground {
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              }
            }
            altText
            title
          }
          image {
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              }
            }
            altText
            title
          }
          thumbnail {
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              }
            }
            altText
            title
          }
          slider {
            content
            topText
          }
          specifications {
            name
            value
          }
          imageFirstStep {
            images {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
              altText
              title
            }
          }
          imageSecondStep {
            images {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
              altText
              title
            }
          }
          imageThirdStep {
            images {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
              altText
              title
            }
          }
          imageFourhtStep {
            images {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
              altText
              title
            }
          }
          contentFirstStep
          contentSecondStep
          contentThirdStep
          contentFourthStep
          titleFourthStep
          gallery {
            image {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
              altText
              title
            }
          }
          heroContent
          heroTag
          name
          titleFirstStep
          titleSecondStep
          titleThirdStep
          titleFourthStep
        }
      }
    }
  }
`

const PageBoat = ({ data }) => {
  const {
    seo,
    title,
    slug,
    boatPostTypeFields: { generalInfo },
  } = data.wpBoat
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
  const [galleryIndex, setGalleryIndex] = useState(0)
  const heroImage = generalInfo.heroBackground

  const {
    heroTag,
    heroContent,
    slider,
    specifications,
    image,
    titleFirstStep,
    titleSecondStep,
    titleThirdStep,
    titleFourthStep,
    contentFirstStep,
    contentSecondStep,
    contentThirdStep,
    contentFourthStep,
    imageFirstStep,
    imageSecondStep,
    imageThirdStep,
    imageFourhtStep,
    gallery,
  } = generalInfo

  return (
    <Layout seo={seo} pageName={title}>
      <TopPageBanner bgImg={heroImage} tag={heroTag}>
        {heroContent}
      </TopPageBanner>

      {slider && slider.length && (
        <S.SliderSection>
          <CustomSlider showDots={true} contained>
            {slider.map(({ topText, content }) => (
              <div>
                <S.SliderText>
                  {topText && <SectionTitle>{parse(topText)}</SectionTitle>}
                  {content && parse(content)}
                </S.SliderText>
              </div>
            ))}
          </CustomSlider>
        </S.SliderSection>
      )}

      <S.KeyFeaturesSection>
        <Container maxWidth="md">
          <SectionTitle mb={1} colorInherit>
            <span>KEY</span> FEATURES
          </SectionTitle>
          {specifications && (
            <Grid
              container
              spacing={4}
              justify="center"
              style={{ marginBottom: "1em" }}
            >
              {specifications.map(({ name, value }) => (
                <Grid item md={4}>
                  <S.KeyFeatureName>{name}</S.KeyFeatureName>
                  <Typography>{value}</Typography>
                </Grid>
              ))}
            </Grid>
          )}

          {image && (
            <S.ImageWrapper>
              <CustomImage src={image} />
            </S.ImageWrapper>
          )}
          <Grid container spacing={4} alignItems="center" justify="center">
            {slug !== "37-cabin-3" && slug !== "39s-open-2" ? (
              <Grid item>
                <CustomButton
                  href={`/boat-builder/${slug}`}
                  className="lightBorder"
                >
                  DESIGN YOURS
                </CustomButton>
              </Grid>
            ) : (
              ""
            )}
            <Grid item>
              <CustomButton href="/contact-us">CONTACT US</CustomButton>
            </Grid>
          </Grid>
        </Container>
      </S.KeyFeaturesSection>

      {imageFirstStep && (
        <TextImgRowSlider images={imageFirstStep}>
          {parse(titleFirstStep)}
          {parse(contentFirstStep)}
        </TextImgRowSlider>
      )}

      {imageSecondStep && (
        <TextImgRowSlider images={imageSecondStep} flipCols>
          {parse(titleSecondStep)}
          {parse(contentSecondStep)}
        </TextImgRowSlider>
      )}

      {imageThirdStep && (
        <TextImgRowSlider images={imageThirdStep}>
          {parse(titleThirdStep)}
          {parse(contentThirdStep)}
        </TextImgRowSlider>
      )}

      {imageFourhtStep && (
        <TextImgRowSlider images={imageFourhtStep} flipCols>
          {parse(titleFourthStep)}
          {parse(contentFourthStep)}
        </TextImgRowSlider>
      )}

      {gallery && gallery.length > 0 && (
        <S.GallerySection>
          <Container>
            <SectionTitle mb={1.5}>
              <span>{`${title} `}</span>
              GALLERY
            </SectionTitle>
          </Container>
          <S.Slider
            showArrows
            centerMode={true}
            slidesToShow={2}
            contained
            centerPadding="50"
          >
            {gallery &&
              gallery.length > 0 &&
              gallery.map((image, index) => (
                <SwiperSlide
                  onClick={() => {
                    setGalleryIndex(index)
                    setIsLightBoxOpen(true)
                  }}
                >
                  <CustomImage src={image.image} />
                </SwiperSlide>
              ))}
          </S.Slider>

          <CustomLightbox
            images={gallery}
            handleLightBoxOpen={setIsLightBoxOpen}
            galleryIndex={galleryIndex}
            handleGalleryIndex={setGalleryIndex}
            isLightBoxOpen={isLightBoxOpen}
          />
        </S.GallerySection>
      )}
    </Layout>
  )
}

export default PageBoat
