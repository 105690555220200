import styled from "styled-components"
import Grid from "@material-ui/core/Grid"

export const Wrapper = styled.section`
  background-color: ${({ graybg }) => (graybg ? "#F1F7F9" : "")};
  ${({ theme }) => theme.sectionPadding};
`

export const GridContainer = styled(Grid)`
  align-items: center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    ${({ flipCols }) =>
      flipCols
        ? `
  .MuiGrid-item {
      &:last-child {
        order: 1;
    }
    
     &:first-child {
        order: 2;
     }
 `
        : ""};
  }

  .prev {
    //z-index: 1;
    left: 15px;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
  }
  .next {
    right: 15px;
    float: right;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
  }

  .arrow-btn {
    top: 50%;
    z-index: 1;
  }
  svg {
    color: white;
  }
`

export const Content = styled.div`
  max-width: 360px;
  margin: auto;

  .custom-button {
    margin-top: 3.5em;
  }
  h2 {
    font-size: 32px;
    color: ${({ theme }) => theme.palette.primary.main};
    margin-bottom: 1em;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      font-size: 1.5rem;
    }

    span {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }

  p {
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
    color: ${({ theme }) => theme.palette.text.primary};
  }
`
