import React from "react"
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"
import ButtonBase from "@material-ui/core/ButtonBase"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"


const ArrowSlickLeft = ({onClick }) => {
  return (
      <ButtonBase
        className="arrow-btn next"
        onClick={onClick}
      >
        <ArrowForwardIosIcon onClick={onClick} color="white"/>
       </ButtonBase>
  )
}
export default ArrowSlickLeft